/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMutation, useQuery } from '@tanstack/react-query';
import { IGoogleLogInModel } from '../../../@types/model/auth/logIn/GoogleLogInModel';
import { IPasswordReset } from '../../../@types/model/auth/password/IPasswordReset';
import { IUser } from '../../../@types/model/auth/user/user';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import UserHttpService from '../../../service/http/right/userHttpService';
import { DELETE_USER_KEY, GET_USERS_KEY, LINK_USER_GOOGLE_ACCOUNT, LOGOUT_USERS_KEY, RESET_PASSWORD_KEY, UNLINK_USER_GOOGLE_ACCOUNT, UPSERT_USER_KEY } from '../queryConstants';
import { queryDeleteSuccessHandler, queryErrorHandler, queryNoReturnContentSuccessHandler, querySuccessHandler, queryUpdateSessionSuccessHandler } from '../queryStateHandlers';

export const useGetUsers = (ignoreRights : boolean | null, isActive : boolean | null) => {
    const query = useQuery<Array<IUser>, Error>({
        queryKey: [GET_USERS_KEY, ignoreRights, isActive],
        queryFn: () => UserHttpService.getUsersList(ignoreRights, isActive),
        onError: queryErrorHandler('Error loading Users.'),
    });

    return query;
};

export const useHandleUpsertUser = () => {
    const result = useMutation<Array<IUser>, Error, Array<IUserUpsert>>({
        mutationKey: [UPSERT_USER_KEY],
        mutationFn: (upsert : Array<IUserUpsert>) => UserHttpService.usersUpsert(upsert),
        onError: queryErrorHandler('Error creating/updating User.'),
        onSuccess: querySuccessHandler<IUser>(GET_USERS_KEY, 'User(s) successfully updated')
    });

    return result;
};

export const useHandleDeleteUser = () => {
    const query = useMutation<void, Error, number>({
        mutationKey: [DELETE_USER_KEY],
        mutationFn: (userId : number) => UserHttpService.userDelete(userId),
        onError: queryErrorHandler('Error deleting User.'),
        onSuccess: queryDeleteSuccessHandler(DELETE_USER_KEY, GET_USERS_KEY, 'User inactivated successfully.'),
    });

    return query;
};

export const useHandleResetPassword = () => {
    const query = useMutation<boolean, Error, IPasswordReset>({
        mutationKey: [RESET_PASSWORD_KEY],
        mutationFn: (data : IPasswordReset) => UserHttpService.resetPassword(data),
        onError: queryErrorHandler('Error resetting password.'),
        onSuccess: queryNoReturnContentSuccessHandler('Password reset successful.')
    });

    return query;
};

export const useHandleLogoutUsers = () => {
    const result = useMutation<void, Error, Array<number>>({
        mutationKey: [LOGOUT_USERS_KEY],
        mutationFn: (userIds : Array<number>) => UserHttpService.logoutUsers(userIds),
        onError: queryErrorHandler('Error logging out users.'),
        onSuccess: queryNoReturnContentSuccessHandler('User(s) logged out successfully.')
    });

    return result;
};

export const useHandleLinkGoogleAccount = () => {
    const result = useMutation<IUser | null, Error, IGoogleLogInModel>({
        mutationKey: [LINK_USER_GOOGLE_ACCOUNT],
        mutationFn: (googleLoginModel : IGoogleLogInModel) => UserHttpService.linkGoogleAccount(googleLoginModel),
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account linked successfully.')
    });

    return result;
};

export const useHandleUnlinkGoogleAccount = () => {
    const result = useMutation<IUser, Error, void>({
        mutationKey: [UNLINK_USER_GOOGLE_ACCOUNT],
        mutationFn: UserHttpService.unlinkGoogleAccount,
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account unlinked successfully.')
    });

    return result;
};
