import axios from 'axios';
import { IUser } from '../../../@types/model/auth/user/user';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import { IPasswordReset } from '../../../@types/model/auth/password/IPasswordReset';
import qs from 'qs';
import { IGoogleLogInModel } from '../../../@types/model/auth/logIn/GoogleLogInModel';

export default class UserHttpService {
    public static getUsersList = async (ignoreRights : boolean | null, isActive : boolean | null) : Promise<Array<IUser>> => {
        const result = await axios.get(`${API_URL}/v1/User/GetList`, {
            params: {
                ignoreRights,
                isActive
            },
            paramsSerializer: qs.stringify
        });
        return result.data;
    }

    public static usersUpsert = async (upsertData : Array<IUserUpsert>) : Promise<Array<IUser>> => {
        const result = await axios.post(`${API_URL}/v1/User/Upsert`, upsertData);
        return result.data;
    }

    public static linkGoogleAccount = async (googleLoginModel : IGoogleLogInModel) : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v1/User/LinkGoogleAccountToUser`, googleLoginModel);
        return result.data;
    }

    public static unlinkGoogleAccount = async () : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v1/User/UnlinkGoogleAccountFromUser`, null);
        return result.data;
    }

    public static logoutUsers = async (userIds : Array<number>) : Promise<void> => {
        await axios.post(`${API_URL}/v1/User/LogoutUsers`, userIds);
    }

    public static requestForgottenPassword = async (emailOrEmployeeNumber : string) : Promise<boolean> => {
        const result = await axios.post(`${API_URL}/v1/User/RequestPasswordReset`, null, {
            params: {
                emailOrEmployeeNumber,
            }
        });
        return result.data;
    }

    public static resetPassword = async (data : IPasswordReset) : Promise<boolean> => {
        const result = await axios.post(`${API_URL}/v1/User/ResetUserPassword`, data);
        return result.data;
    }

    public static userDelete = async (userId : number) : Promise<void> => {
        await axios.delete(`${API_URL}/v1/User/Delete`, {
            params: { userId },
        });
    }
}
